import React from 'react'

export const NotFound = () => {
    return (
        <section className='cars section-body body' style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "10rem" }}>404</h1>
            <h3 style={{ fontSize: "2rem" }}>Không tìm thấy trang</h3>
        </section>
    )
}
